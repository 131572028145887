@import 'src/styles/mixins';

.header {
  & {
    padding: 0 0 16px 0;
    position: relative;
  }

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 2px;
    background: #000000;
  }

  .heading {
    @include font-style-h4;

    margin-bottom: 8px;
    padding-right: 56px;
  }

  .text {
    @include font-style-body-3;
    white-space: nowrap;
    margin-left: 8px;
  }

  .buttons {
    margin-bottom: auto;
    display: flex;
    gap: 16px;
    height: 2.5em;
    white-space: nowrap;
  }
}

@media (min-width: 640px) {
  .header {
    & {
      background: #ffffff;
    }

    &:after {
      width: 100%;
    }
  }
}

@media (min-width: 1024px) {
  .header {
    & {
      display: flex;
      gap: 8px;
      align-items: flex-start;
      justify-content: space-between;
    }

    &:after {
      width: 100%;
    }

    .buttons {
      margin-top: 0;
    }
  }
}

@media (min-width: 1550px) {
  .header {
    .info {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      gap: 8px;
    }

    .heading {
      margin-bottom: 0;
      padding-right: 0;
    }
  }
}
