.wrapper {
  width: 100%;
  padding: 16px;
  background: #f5f7fa;
  display: flex;
  flex-direction: column;
  gap: 16px;
  scrollbar-width: none;
  -ms-overflow-style: none;
  margin: 32px 0 32px 0;
}

@media (min-width: 1024px) {
  .wrapper {
    overflow-y: auto;
    margin: 0;
  }
}


.wrapper::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.methods {
  width: 100%;
  background: #f5f7fa;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
