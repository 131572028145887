@import 'src/styles/mixins';

.method {
  & {
    width: 100%;
    height: 100%;
  }

  :global(.swiper) {
    height: calc(100% - 68px);
  }

  :global(.swiper-slide) {
    height: auto;
  }

  :global(.swiper-pagination) {
    width: 110px;
    top: 210px !important;
    left: 30px !important;
    transform: none !important;
    text-align: left;
  }

  :global(.swiper-pagination-bullet) {
    @include font-style-body-2;

    width: 100%;
    height: auto;
    border-radius: 0;
    background: transparent;
    color: rgb(0 0 0 / 60%);
    padding: 10px 0 10px 16px;
    opacity: 1;
  }

  :global(.swiper-pagination-bullet-active) {
    border-left: 4px solid #000000;
    padding-left: 12px;
    color: #000000;
  }

  .description {
    margin-top: 40px;
  }

  .banner img {
    width: 100%;
    height: 195px;
    object-fit: cover;
    border-radius: 12px;
  }

  .description .text {
    @include font-style-body-1;

    margin-top: 25px;
  }
}

@media (min-width: 1024px) {
  .method {
    & {
      display: flex;
      gap: 32px;
    }

    .banner img {
      height: 311px;
    }

    :global(.swiper) {
      height: calc(100% - 50px);
    }
  }
}

@media (min-width: 1280px) {
  .method {
    .description {
      display: flex;
      gap: 32px;
    }

    .banner {
      flex: 0 50%;
    }

    .description .text {
      flex: 0 50%;
      margin-top: 0;
    }
  }
}
