@import 'src/styles/mixins';

.description {
  & {
    padding-top: 16px;
  }

  .text {
    @include font-style-body-1;
  }

  .wrapper {
    margin-top: 52px;
    display: flex;
    flex-direction: column;
    gap: 48px;
  }
}

@media (min-width: 1024px) {
  .description {
    & {
      height: 100%;
      padding: 16px 32px 0 0;
      width: 75%;
      overflow-y: auto;
      scrollbar-width: none;
      -ms-overflow-style: none;
    }

    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
  }
}
