@import 'src/styles/mixins';

.methodCard {
  & {
    @include font-style-body-2;

    border: 1px solid #000000;
    border-radius: 12px;
    position: relative;
    padding: 40px 24px 24px;
    width: 100%;
  }

  .header {
    @include font-style-body-1;

    line-height: 20px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
    padding: 12px 16px;
    background: #ffdf69;
    border: 1px solid #000000;
    border-radius: 12px;
    position: absolute;
    top: -25px;
    left: 15px;
    font-weight: $font-weight-bold;
  }

  li {
    position: relative;
    padding-left: 20px;
  }

  li + li {
    margin-top: 12px;
  }

  li::before {
    content: '';
    position: absolute;
    top: 8px;
    left: 7px;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background: #000000;
  }
}
