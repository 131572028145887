.recipeDetail {
  & {
    height: 100%;
  }
}

.wrapper {
  height: 100%;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.wrapper::-webkit-scrollbar {
  width: 0;
  height: 0;
}