.modal {
  position: relative;
  .wrapper {
    overflow-y: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }

  .wrapper::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
}
