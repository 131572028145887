.sideBar {
  margin: -24px 0 -24px -32px;
  padding: 18px 24px;
  width: 161px;
  height: calc(100% + 48px);
  background: #ffdf69;
  display: none;
}

@media (min-width: 1024px) {
  .sideBar {
    display: block;
    flex: 0 0 161px;
  }
}
