@import 'src/styles/mixins';

.steps {
  & {
    margin-top: 80px;
  }

  .heading {
    @include font-style-h6;
  }

  .image {
    margin-top: 16px;
  }

  .wrapper {
    margin-top: 90px;
  }

  .card {
    padding: 32px;
    background: #f5f7fa;
    border-radius: 15px;
  }

  .card + .card {
    margin-top: 16px;
  }

  ul {
    margin-top: 32px;
  }

  li {
    display: flex;
    gap: 12px;
  }

  li + li {
    margin-top: 24px;
  }

  .number {
    @include font-style-body-2;

    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    background: #000000;
    color: #ffffff;
    flex-shrink: 0;
    border-radius: 50%;
  }

  .text {
    @include font-style-body-1;
  }
}

@media (min-width: 1280px) {
  .steps {
    .wrapper {
      display: flex;
      gap: 16px;
    }

    .card + .card {
      margin-top: 0;
    }

    .card {
      flex: 0 50%;
    }
  }
}
