.descriptionCards {
  & {
    margin-top: 80px;
    display: flex;
    flex-direction: column;
    gap: 35px;
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    gap: 35px;
  }
}

@media (min-width: 1024px) {
  .descriptionCards {
    & {
      flex-direction: row;
      align-items: stretch;
      gap: 16px;
    }

    .wrapper {
      flex: 1 1 100%;
      flex-direction: row;
      flex-wrap: wrap;
    }
  }
}

@media (min-width: 1280px) {
  .descriptionCards {
    & {
      flex-direction: row;
      align-items: stretch;
      gap: 16px;
    }

    .wrapper {
      flex: 1 1 200%;
      flex-direction: row;
      gap: 16px;
      flex-wrap: unset;
    }
  }
}
