// colors
$color-white: #ffffff;
$color-black: #000000;

// theme
$theme-main: #3fa0d7;

// typography
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;
$font-weight-extra-bold: 900;
