.dynamicDeleteButton {
  position: relative;
  top: 4px;
  margin: 0 8px;
  color: #999;
  font-size: 24px;
  cursor: pointer;
  transition: all 0.3s;
}
.dynamicDeleteButton:hover {
  color: #777;
}
.dynamicDeleteButton[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}
