*, ::before, ::after {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x:  ;
  --tw-pan-y:  ;
  --tw-pinch-zoom:  ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position:  ;
  --tw-gradient-via-position:  ;
  --tw-gradient-to-position:  ;
  --tw-ordinal:  ;
  --tw-slashed-zero:  ;
  --tw-numeric-figure:  ;
  --tw-numeric-spacing:  ;
  --tw-numeric-fraction:  ;
  --tw-ring-inset:  ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur:  ;
  --tw-brightness:  ;
  --tw-contrast:  ;
  --tw-grayscale:  ;
  --tw-hue-rotate:  ;
  --tw-invert:  ;
  --tw-saturate:  ;
  --tw-sepia:  ;
  --tw-drop-shadow:  ;
  --tw-backdrop-blur:  ;
  --tw-backdrop-brightness:  ;
  --tw-backdrop-contrast:  ;
  --tw-backdrop-grayscale:  ;
  --tw-backdrop-hue-rotate:  ;
  --tw-backdrop-invert:  ;
  --tw-backdrop-opacity:  ;
  --tw-backdrop-saturate:  ;
  --tw-backdrop-sepia:  ;
}
::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x:  ;
  --tw-pan-y:  ;
  --tw-pinch-zoom:  ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position:  ;
  --tw-gradient-via-position:  ;
  --tw-gradient-to-position:  ;
  --tw-ordinal:  ;
  --tw-slashed-zero:  ;
  --tw-numeric-figure:  ;
  --tw-numeric-spacing:  ;
  --tw-numeric-fraction:  ;
  --tw-ring-inset:  ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur:  ;
  --tw-brightness:  ;
  --tw-contrast:  ;
  --tw-grayscale:  ;
  --tw-hue-rotate:  ;
  --tw-invert:  ;
  --tw-saturate:  ;
  --tw-sepia:  ;
  --tw-drop-shadow:  ;
  --tw-backdrop-blur:  ;
  --tw-backdrop-brightness:  ;
  --tw-backdrop-contrast:  ;
  --tw-backdrop-grayscale:  ;
  --tw-backdrop-hue-rotate:  ;
  --tw-backdrop-invert:  ;
  --tw-backdrop-opacity:  ;
  --tw-backdrop-saturate:  ;
  --tw-backdrop-sepia:  ;
}
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}
.pointer-events-none {
  pointer-events: none;
}
.visible {
  visibility: visible;
}
.invisible {
  visibility: hidden;
}
.static {
  position: static;
}
.fixed {
  position: fixed;
}
.absolute {
  position: absolute;
}
.relative {
  position: relative;
}
.inset-0 {
  inset: 0px;
}
.inset-y-0 {
  top: 0px;
  bottom: 0px;
}
.bottom-2 {
  bottom: 0.5rem;
}
.left-0 {
  left: 0px;
}
.right-0 {
  right: 0px;
}
.top-0 {
  top: 0px;
}
.top-16 {
  top: 4rem;
}
.z-10 {
  z-index: 10;
}
.z-20 {
  z-index: 20;
}
.-m-1 {
  margin: -0.25rem;
}
.m-0 {
  margin: 0px;
}
.m-2 {
  margin: 0.5rem;
}
.m-auto {
  margin: auto;
}
.mx-\[-4px\] {
  margin-left: -4px;
  margin-right: -4px;
}
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.my-2 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}
.-mb-1 {
  margin-bottom: -0.25rem;
}
.mb-1 {
  margin-bottom: 0.25rem;
}
.mb-12 {
  margin-bottom: 3rem;
}
.mb-16 {
  margin-bottom: 4rem;
}
.mb-2 {
  margin-bottom: 0.5rem;
}
.mb-4 {
  margin-bottom: 1rem;
}
.mb-8 {
  margin-bottom: 2rem;
}
.mb-auto {
  margin-bottom: auto;
}
.ml-3 {
  margin-left: 0.75rem;
}
.ml-4 {
  margin-left: 1rem;
}
.ml-\[-28px\] {
  margin-left: -28px;
}
.ml-\[-4px\] {
  margin-left: -4px;
}
.ml-\[-8px\] {
  margin-left: -8px;
}
.ml-\[28px\] {
  margin-left: 28px;
}
.ml-\[34px\] {
  margin-left: 34px;
}
.ml-auto {
  margin-left: auto;
}
.mr-10 {
  margin-right: 2.5rem;
}
.mr-2 {
  margin-right: 0.5rem;
}
.mr-6 {
  margin-right: 1.5rem;
}
.mr-7 {
  margin-right: 1.75rem;
}
.mt-0 {
  margin-top: 0px;
}
.mt-0\.5 {
  margin-top: 0.125rem;
}
.mt-1 {
  margin-top: 0.25rem;
}
.mt-1\.5 {
  margin-top: 0.375rem;
}
.mt-12 {
  margin-top: 3rem;
}
.mt-2 {
  margin-top: 0.5rem;
}
.mt-3 {
  margin-top: 0.75rem;
}
.mt-6 {
  margin-top: 1.5rem;
}
.mt-8 {
  margin-top: 2rem;
}
.mt-\[90px\] {
  margin-top: 90px;
}
.mt-auto {
  margin-top: auto;
}
.box-border {
  box-sizing: border-box;
}
.block {
  display: block;
}
.inline {
  display: inline;
}
.flex {
  display: flex;
}
.inline-flex {
  display: inline-flex;
}
.grid {
  display: grid;
}
.hidden {
  display: none;
}
.aspect-square {
  aspect-ratio: 1 / 1;
}
.h-10 {
  height: 2.5rem;
}
.h-12 {
  height: 3rem;
}
.h-16 {
  height: 4rem;
}
.h-3 {
  height: 0.75rem;
}
.h-3\.5 {
  height: 0.875rem;
}
.h-4 {
  height: 1rem;
}
.h-5 {
  height: 1.25rem;
}
.h-6 {
  height: 1.5rem;
}
.h-8 {
  height: 2rem;
}
.h-\[1\.5em\] {
  height: 1.5em;
}
.h-\[160px\] {
  height: 160px;
}
.h-\[1em\] {
  height: 1em;
}
.h-\[1px\] {
  height: 1px;
}
.h-\[2\.5em\] {
  height: 2.5em;
}
.h-\[20px\] {
  height: 20px;
}
.h-\[240px\] {
  height: 240px;
}
.h-\[289px\] {
  height: 289px;
}
.h-\[44px\] {
  height: 44px;
}
.h-auto {
  height: auto;
}
.h-fit {
  height: -moz-fit-content;
  height: fit-content;
}
.h-full {
  height: 100%;
}
.max-h-60 {
  max-height: 15rem;
}
.max-h-\[80\%\] {
  max-height: 80%;
}
.min-h-\[120px\] {
  min-height: 120px;
}
.min-h-\[144px\] {
  min-height: 144px;
}
.min-h-full {
  min-height: 100%;
}
.min-h-screen {
  min-height: 100vh;
}
.w-0 {
  width: 0px;
}
.w-12 {
  width: 3rem;
}
.w-20 {
  width: 5rem;
}
.w-3 {
  width: 0.75rem;
}
.w-3\.5 {
  width: 0.875rem;
}
.w-4 {
  width: 1rem;
}
.w-40 {
  width: 10rem;
}
.w-48 {
  width: 12rem;
}
.w-5 {
  width: 1.25rem;
}
.w-6 {
  width: 1.5rem;
}
.w-8 {
  width: 2rem;
}
.w-\[1\.5em\] {
  width: 1.5em;
}
.w-\[113px\] {
  width: 113px;
}
.w-\[20px\] {
  width: 20px;
}
.w-\[224px\] {
  width: 224px;
}
.w-\[2em\] {
  width: 2em;
}
.w-\[320px\] {
  width: 320px;
}
.w-\[44px\] {
  width: 44px;
}
.w-\[50\%\] {
  width: 50%;
}
.w-auto {
  width: auto;
}
.w-fit {
  width: -moz-fit-content;
  width: fit-content;
}
.w-full {
  width: 100%;
}
.min-w-\[141px\] {
  min-width: 141px;
}
.min-w-\[300px\] {
  min-width: 300px;
}
.max-w-\[100px\] {
  max-width: 100px;
}
.max-w-\[1040px\] {
  max-width: 1040px;
}
.max-w-\[1408px\] {
  max-width: 1408px;
}
.max-w-\[1600px\] {
  max-width: 1600px;
}
.max-w-\[250px\] {
  max-width: 250px;
}
.max-w-\[413px\] {
  max-width: 413px;
}
.max-w-\[500px\] {
  max-width: 500px;
}
.max-w-\[600px\] {
  max-width: 600px;
}
.max-w-\[80\%\] {
  max-width: 80%;
}
.flex-1 {
  flex: 1 1 0%;
}
.flex-none {
  flex: none;
}
.flex-shrink-0 {
  flex-shrink: 0;
}
.flex-grow-0 {
  flex-grow: 0;
}
.grow {
  flex-grow: 1;
}
.grow-\[999\] {
  flex-grow: 999;
}
.basis-0 {
  flex-basis: 0px;
}
.basis-\[60\%\] {
  flex-basis: 60%;
}
.origin-top-right {
  transform-origin: top right;
}
.translate-y-0 {
  --tw-translate-y: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.translate-y-4 {
  --tw-translate-y: 1rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.rotate-90 {
  --tw-rotate: 90deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.scale-100 {
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.scale-95 {
  --tw-scale-x: .95;
  --tw-scale-y: .95;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
@keyframes pulse {
  50% {
    opacity: .5;
  }
}
.animate-pulse {
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}
@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
.animate-spin {
  animation: spin 1s linear infinite;
}
.\!cursor-pointer {
  cursor: pointer !important;
}
.cursor-pointer {
  cursor: pointer;
}
.select-none {
  -webkit-user-select: none;
          user-select: none;
}
.resize-none {
  resize: none;
}
.resize-y {
  resize: vertical;
}
.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}
.flex-row {
  flex-direction: row;
}
.flex-row-reverse {
  flex-direction: row-reverse;
}
.flex-col {
  flex-direction: column;
}
.flex-col-reverse {
  flex-direction: column-reverse;
}
.flex-wrap {
  flex-wrap: wrap;
}
.flex-nowrap {
  flex-wrap: nowrap;
}
.items-start {
  align-items: flex-start;
}
.items-end {
  align-items: flex-end;
}
.items-center {
  align-items: center;
}
.items-stretch {
  align-items: stretch;
}
.justify-start {
  justify-content: flex-start;
}
.justify-center {
  justify-content: center;
}
.justify-between {
  justify-content: space-between;
}
.justify-around {
  justify-content: space-around;
}
.\!gap-2 {
  gap: 0.5rem !important;
}
.gap-0 {
  gap: 0px;
}
.gap-0\.5 {
  gap: 0.125rem;
}
.gap-1 {
  gap: 0.25rem;
}
.gap-1\.5 {
  gap: 0.375rem;
}
.gap-12 {
  gap: 3rem;
}
.gap-2 {
  gap: 0.5rem;
}
.gap-3 {
  gap: 0.75rem;
}
.gap-4 {
  gap: 1rem;
}
.gap-5 {
  gap: 1.25rem;
}
.gap-6 {
  gap: 1.5rem;
}
.gap-8 {
  gap: 2rem;
}
.gap-x-6 {
  column-gap: 1.5rem;
}
.gap-y-8 {
  row-gap: 2rem;
}
.gap-y-\[1px\] {
  row-gap: 1px;
}
.space-x-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1rem * var(--tw-space-x-reverse));
  margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
}
.self-center {
  align-self: center;
}
.overflow-auto {
  overflow: auto;
}
.overflow-hidden {
  overflow: hidden;
}
.overflow-visible {
  overflow: visible;
}
.overflow-y-auto {
  overflow-y: auto;
}
.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.whitespace-nowrap {
  white-space: nowrap;
}
.whitespace-pre {
  white-space: pre;
}
.whitespace-break-spaces {
  white-space: break-spaces;
}
.break-words {
  overflow-wrap: break-word;
}
.rounded-full {
  border-radius: 9999px;
}
.rounded-lg {
  border-radius: 0.5rem;
}
.rounded-md {
  border-radius: 0.375rem;
}
.rounded-none {
  border-radius: 0px;
}
.rounded-sm {
  border-radius: 0.125rem;
}
.rounded-xl {
  border-radius: 0.75rem;
}
.rounded-l-none {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
.rounded-l-xl {
  border-top-left-radius: 0.75rem;
  border-bottom-left-radius: 0.75rem;
}
.rounded-r-none {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
.rounded-r-xl {
  border-top-right-radius: 0.75rem;
  border-bottom-right-radius: 0.75rem;
}
.border {
  border-width: 1px;
}
.border-2 {
  border-width: 2px;
}
.border-b {
  border-bottom-width: 1px;
}
.border-b-2 {
  border-bottom-width: 2px;
}
.border-l-0 {
  border-left-width: 0px;
}
.border-t {
  border-top-width: 1px;
}
.\!border-solid {
  border-style: solid !important;
}
.border-solid {
  border-style: solid;
}
.border-dashed {
  border-style: dashed;
}
.\!border-\[\#CBD2D9\] {
  --tw-border-opacity: 1 !important;
  border-color: rgb(203 210 217 / var(--tw-border-opacity)) !important;
}
.\!border-black {
  --tw-border-opacity: 1 !important;
  border-color: rgb(0 0 0 / var(--tw-border-opacity)) !important;
}
.border-\[\#CBD2D9\] {
  --tw-border-opacity: 1;
  border-color: rgb(203 210 217 / var(--tw-border-opacity));
}
.border-black {
  --tw-border-opacity: 1;
  border-color: rgb(0 0 0 / var(--tw-border-opacity));
}
.border-black\/\[0\.6\] {
  border-color: rgb(0 0 0 / 0.6);
}
.border-deletered {
  --tw-border-opacity: 1;
  border-color: rgb(215 107 99 / var(--tw-border-opacity));
}
.border-gray-100 {
  --tw-border-opacity: 1;
  border-color: rgb(227 231 235 / var(--tw-border-opacity));
}
.border-gray-200 {
  --tw-border-opacity: 1;
  border-color: rgb(203 210 217 / var(--tw-border-opacity));
}
.border-helvetiablue {
  --tw-border-opacity: 1;
  border-color: rgb(57 175 182 / var(--tw-border-opacity));
}
.border-transparent {
  border-color: transparent;
}
.border-white {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
}
.border-white-40 {
  --tw-border-opacity: 1;
  border-color: rgb(138 138 138 / var(--tw-border-opacity));
}
.border-b-black\/60 {
  border-bottom-color: rgb(0 0 0 / 0.6);
}
.\!bg-gray-100 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(227 231 235 / var(--tw-bg-opacity)) !important;
}
.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}
.bg-deletered {
  --tw-bg-opacity: 1;
  background-color: rgb(215 107 99 / var(--tw-bg-opacity));
}
.bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(227 231 235 / var(--tw-bg-opacity));
}
.bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(203 210 217 / var(--tw-bg-opacity));
}
.bg-gray-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(154 165 177 / var(--tw-bg-opacity));
}
.bg-gray-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(245 247 250 / var(--tw-bg-opacity));
}
.bg-gray-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(97 110 124 / var(--tw-bg-opacity));
}
.bg-gray-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(50 63 75 / var(--tw-bg-opacity));
}
.bg-gray-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 51 / var(--tw-bg-opacity));
}
.bg-red-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 226 226 / var(--tw-bg-opacity));
}
.bg-red-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(220 38 38 / var(--tw-bg-opacity));
}
.bg-slate-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(226 232 240 / var(--tw-bg-opacity));
}
.bg-transparent {
  background-color: transparent;
}
.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}
.bg-opacity-40 {
  --tw-bg-opacity: 0.4;
}
.bg-opacity-75 {
  --tw-bg-opacity: 0.75;
}
.fill-gray-600 {
  fill: #52606D;
}
.object-contain {
  object-fit: contain;
}
.object-cover {
  object-fit: cover;
}
.p-0 {
  padding: 0px;
}
.p-1 {
  padding: 0.25rem;
}
.p-2 {
  padding: 0.5rem;
}
.p-4 {
  padding: 1rem;
}
.p-6 {
  padding: 1.5rem;
}
.p-8 {
  padding: 2rem;
}
.px-0 {
  padding-left: 0px;
  padding-right: 0px;
}
.px-1 {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}
.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}
.py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.py-1\.5 {
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
}
.py-12 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}
.py-16 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}
.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.py-3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}
.py-\[10px\] {
  padding-top: 10px;
  padding-bottom: 10px;
}
.py-\[6px\] {
  padding-top: 6px;
  padding-bottom: 6px;
}
.pb-1 {
  padding-bottom: 0.25rem;
}
.pb-12 {
  padding-bottom: 3rem;
}
.pb-14 {
  padding-bottom: 3.5rem;
}
.pb-2 {
  padding-bottom: 0.5rem;
}
.pb-2\.5 {
  padding-bottom: 0.625rem;
}
.pb-3 {
  padding-bottom: 0.75rem;
}
.pb-32 {
  padding-bottom: 8rem;
}
.pb-4 {
  padding-bottom: 1rem;
}
.pb-8 {
  padding-bottom: 2rem;
}
.pl-1 {
  padding-left: 0.25rem;
}
.pl-2 {
  padding-left: 0.5rem;
}
.pl-3 {
  padding-left: 0.75rem;
}
.pl-4 {
  padding-left: 1rem;
}
.pr-1 {
  padding-right: 0.25rem;
}
.pr-10 {
  padding-right: 2.5rem;
}
.pr-2 {
  padding-right: 0.5rem;
}
.pr-3 {
  padding-right: 0.75rem;
}
.pr-6 {
  padding-right: 1.5rem;
}
.pr-8 {
  padding-right: 2rem;
}
.pt-0 {
  padding-top: 0px;
}
.pt-0\.5 {
  padding-top: 0.125rem;
}
.pt-1 {
  padding-top: 0.25rem;
}
.pt-14 {
  padding-top: 3.5rem;
}
.pt-2 {
  padding-top: 0.5rem;
}
.pt-4 {
  padding-top: 1rem;
}
.pt-8 {
  padding-top: 2rem;
}
.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}
.text-start {
  text-align: start;
}
.font-\[\'Inter\'\] {
  font-family: 'Inter';
}
.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}
.text-\[10px\] {
  font-size: 10px;
}
.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}
.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}
.text-xs {
  font-size: 0.75rem;
  line-height: 1rem;
}
.font-black {
  font-weight: 900;
}
.font-bold {
  font-weight: 700;
}
.font-extrabold {
  font-weight: 800;
}
.font-light {
  font-weight: 300;
}
.font-medium {
  font-weight: 500;
}
.font-normal {
  font-weight: 400;
}
.font-semibold {
  font-weight: 600;
}
.capitalize {
  text-transform: capitalize;
}
.italic {
  font-style: italic;
}
.leading-3 {
  line-height: .75rem;
}
.leading-4 {
  line-height: 1rem;
}
.leading-5 {
  line-height: 1.25rem;
}
.leading-6 {
  line-height: 1.5rem;
}
.leading-8 {
  line-height: 2rem;
}
.leading-\[29px\] {
  line-height: 29px;
}
.tracking-tight {
  letter-spacing: -0.025em;
}
.tracking-wide {
  letter-spacing: 0.025em;
}
.\!text-black {
  --tw-text-opacity: 1 !important;
  color: rgb(0 0 0 / var(--tw-text-opacity)) !important;
}
.text-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}
.text-black\/\[0\.4\] {
  color: rgb(0 0 0 / 0.4);
}
.text-black\/\[0\.6\] {
  color: rgb(0 0 0 / 0.6);
}
.text-gray-200 {
  --tw-text-opacity: 1;
  color: rgb(203 210 217 / var(--tw-text-opacity));
}
.text-gray-300 {
  --tw-text-opacity: 1;
  color: rgb(154 165 177 / var(--tw-text-opacity));
}
.text-gray-400 {
  --tw-text-opacity: 1;
  color: rgb(123 135 148 / var(--tw-text-opacity));
}
.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(97 110 124 / var(--tw-text-opacity));
}
.text-gray-600 {
  --tw-text-opacity: 1;
  color: rgb(82 96 109 / var(--tw-text-opacity));
}
.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgb(62 76 89 / var(--tw-text-opacity));
}
.text-gray-800 {
  --tw-text-opacity: 1;
  color: rgb(50 63 75 / var(--tw-text-opacity));
}
.text-gray-900 {
  --tw-text-opacity: 1;
  color: rgb(31 41 51 / var(--tw-text-opacity));
}
.text-helvetiablue {
  --tw-text-opacity: 1;
  color: rgb(57 175 182 / var(--tw-text-opacity));
}
.text-red-600 {
  --tw-text-opacity: 1;
  color: rgb(220 38 38 / var(--tw-text-opacity));
}
.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}
.text-opacity-70 {
  --tw-text-opacity: 0.7;
}
.text-opacity-80 {
  --tw-text-opacity: 0.8;
}
.opacity-0 {
  opacity: 0;
}
.opacity-100 {
  opacity: 1;
}
.shadow-hard {
  --tw-shadow: 3px 3px black;
  --tw-shadow-colored: 3px 3px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow-lg {
  --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow-md {
  --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow-sm {
  --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow-xl {
  --tw-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.outline {
  outline-style: solid;
}
.outline-1 {
  outline-width: 1px;
}
.outline-offset-\[-1px\] {
  outline-offset: -1px;
}
.outline-white {
  outline-color: #fff;
}
.ring-1 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}
.ring-inset {
  --tw-ring-inset: inset;
}
.ring-black {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(0 0 0 / var(--tw-ring-opacity));
}
.ring-gray-200 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(203 210 217 / var(--tw-ring-opacity));
}
.ring-gray-300 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(154 165 177 / var(--tw-ring-opacity));
}
.ring-opacity-5 {
  --tw-ring-opacity: 0.05;
}
.blur {
  --tw-blur: blur(8px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
.transition-all {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
.transition-colors {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
.transition-opacity {
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
.duration-100 {
  transition-duration: 100ms;
}
.duration-200 {
  transition-duration: 200ms;
}
.duration-300 {
  transition-duration: 300ms;
}
.duration-75 {
  transition-duration: 75ms;
}
.ease-in {
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
}
.ease-out {
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
}

body {
  background-color: #f5f7fa;
  font-family: Inter;
  font-style: normal;
}

.placeholder\:italic::placeholder {
  font-style: italic;
}

.placeholder\:text-gray-200::placeholder {
  --tw-text-opacity: 1;
  color: rgb(203 210 217 / var(--tw-text-opacity));
}

.hover\:mb-\[4px\]:hover {
  margin-bottom: 4px;
}

.hover\:mt-\[-4px\]:hover {
  margin-top: -4px;
}

.hover\:cursor-pointer:hover {
  cursor: pointer;
}

.hover\:border-2:hover {
  border-width: 2px;
}

.hover\:border-gray-100:hover {
  --tw-border-opacity: 1;
  border-color: rgb(227 231 235 / var(--tw-border-opacity));
}

.hover\:border-gray-200:hover {
  --tw-border-opacity: 1;
  border-color: rgb(203 210 217 / var(--tw-border-opacity));
}

.hover\:border-helvetiablue:hover {
  --tw-border-opacity: 1;
  border-color: rgb(57 175 182 / var(--tw-border-opacity));
}

.hover\:border-helvetiapurple:hover {
  --tw-border-opacity: 1;
  border-color: rgb(129 96 173 / var(--tw-border-opacity));
}

.hover\:border-helvetiared:hover {
  --tw-border-opacity: 1;
  border-color: rgb(229 21 23 / var(--tw-border-opacity));
}

.hover\:bg-gray-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(227 231 235 / var(--tw-bg-opacity));
}

.hover\:bg-gray-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(203 210 217 / var(--tw-bg-opacity));
}

.hover\:bg-gray-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(245 247 250 / var(--tw-bg-opacity));
}

.hover\:bg-gray-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(62 76 89 / var(--tw-bg-opacity));
}

.hover\:bg-helvetiablue:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(57 175 182 / var(--tw-bg-opacity));
}

.hover\:bg-red-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(239 68 68 / var(--tw-bg-opacity));
}

.hover\:bg-white:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.hover\:bg-opacity-20:hover {
  --tw-bg-opacity: 0.2;
}

.hover\:bg-opacity-40:hover {
  --tw-bg-opacity: 0.4;
}

.hover\:bg-opacity-70:hover {
  --tw-bg-opacity: 0.7;
}

.hover\:pl-2:hover {
  padding-left: 0.5rem;
}

.hover\:text-black:hover {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.hover\:text-white:hover {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.hover\:shadow-popblue:hover {
  --tw-shadow: 6px 6px rgb(57 175 182 / 1);
  --tw-shadow-colored: 6px 6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:shadow-poppurple:hover {
  --tw-shadow: 6px 6px rgb(129 96 173 / 1);
  --tw-shadow-colored: 6px 6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:shadow-popred:hover {
  --tw-shadow: 6px 6px rgb(229 21 23 / 1);
  --tw-shadow-colored: 6px 6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.focus\:z-10:focus {
  z-index: 10;
}

.focus\:rounded-md:focus {
  border-radius: 0.375rem;
}

.focus\:bg-transparent:focus {
  background-color: transparent;
}

.focus\:pl-2:focus {
  padding-left: 0.5rem;
}

.focus\:outline-none:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.focus\:outline-black:focus {
  outline-color: #000;
}

.focus\:ring:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus\:ring-0:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus\:ring-2:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus\:ring-inset:focus {
  --tw-ring-inset: inset;
}

.focus\:ring-black:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(0 0 0 / var(--tw-ring-opacity));
}

.focus\:ring-white:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(255 255 255 / var(--tw-ring-opacity));
}

.focus\:ring-opacity-40:focus {
  --tw-ring-opacity: 0.4;
}

.focus\:ring-offset-2:focus {
  --tw-ring-offset-width: 2px;
}

.focus\:ring-offset-gray-800:focus {
  --tw-ring-offset-color: #323F4B;
}

.focus-visible\:border-indigo-500:focus-visible {
  --tw-border-opacity: 1;
  border-color: rgb(99 102 241 / var(--tw-border-opacity));
}

.focus-visible\:ring-2:focus-visible {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus-visible\:ring-white:focus-visible {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(255 255 255 / var(--tw-ring-opacity));
}

.focus-visible\:ring-opacity-75:focus-visible {
  --tw-ring-opacity: 0.75;
}

.focus-visible\:ring-offset-2:focus-visible {
  --tw-ring-offset-width: 2px;
}

.focus-visible\:ring-offset-orange-300:focus-visible {
  --tw-ring-offset-color: #fdba74;
}

.group:invalid .group-invalid\:pointer-events-none {
  pointer-events: none;
}

.group:invalid .group-invalid\:opacity-30 {
  opacity: 0.3;
}

.group\/info:hover .group-hover\/info\:visible {
  visibility: visible;
}

.group\/step:hover .group-hover\/step\:visible {
  visibility: visible;
}

.group:hover .group-hover\:visible {
  visibility: visible;
}

.group:hover .group-hover\:mb-\[2px\] {
  margin-bottom: 2px;
}

.group:hover .group-hover\:mt-\[-2px\] {
  margin-top: -2px;
}

.group:hover .group-hover\:flex {
  display: flex;
}

.group\/info:hover .group-hover\/info\:border-gray-100 {
  --tw-border-opacity: 1;
  border-color: rgb(227 231 235 / var(--tw-border-opacity));
}

.group\/step:hover .group-hover\/step\:border-gray-100 {
  --tw-border-opacity: 1;
  border-color: rgb(227 231 235 / var(--tw-border-opacity));
}

.group\/info:hover .group-hover\/info\:bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.group\/step:hover .group-hover\/step\:bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.group:hover .group-hover\:shadow-pop {
  --tw-shadow: 5px 5px black;
  --tw-shadow-colored: 5px 5px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.group\/step:hover .group-hover\/step\:outline-gray-100 {
  outline-color: #E3E7EB;
}

.group\/info:hover .group-hover\/info\:outline-gray-100 {
  outline-color: #E3E7EB;
}

@media (prefers-color-scheme: dark) {
  .dark\:fill-gray-300 {
    fill: #9AA5B1;
  }
  .dark\:text-gray-600 {
    --tw-text-opacity: 1;
    color: rgb(82 96 109 / var(--tw-text-opacity));
  }
}

@media (min-width: 640px) {
  .sm\:mx-0 {
    margin-left: 0px;
    margin-right: 0px;
  }
  .sm\:mx-auto {
    margin-left: auto;
    margin-right: auto;
  }
  .sm\:my-8 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .sm\:ml-3 {
    margin-left: 0.75rem;
  }
  .sm\:ml-4 {
    margin-left: 1rem;
  }
  .sm\:mt-0 {
    margin-top: 0px;
  }
  .sm\:mt-\[87px\] {
    margin-top: 87px;
  }
  .sm\:block {
    display: block;
  }
  .sm\:flex {
    display: flex;
  }
  .sm\:hidden {
    display: none;
  }
  .sm\:h-10 {
    height: 2.5rem;
  }
  .sm\:h-5\/6 {
    height: 83.333333%;
  }
  .sm\:h-fit {
    height: -moz-fit-content;
    height: fit-content;
  }
  .sm\:w-10 {
    width: 2.5rem;
  }
  .sm\:w-\[500px\] {
    width: 500px;
  }
  .sm\:w-auto {
    width: auto;
  }
  .sm\:w-full {
    width: 100%;
  }
  .sm\:max-w-\[84\%\] {
    max-width: 84%;
  }
  .sm\:max-w-lg {
    max-width: 32rem;
  }
  .sm\:translate-y-0 {
    --tw-translate-y: 0px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }
  .sm\:scale-100 {
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }
  .sm\:scale-95 {
    --tw-scale-x: .95;
    --tw-scale-y: .95;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }
  .sm\:auto-rows-\[minmax\(0\2c 141px\)\] {
    grid-auto-rows: minmax(0,141px);
  }
  .sm\:auto-rows-\[minmax\(0\2c 200px\)\] {
    grid-auto-rows: minmax(0,200px);
  }
  .sm\:flex-row {
    flex-direction: row;
  }
  .sm\:flex-row-reverse {
    flex-direction: row-reverse;
  }
  .sm\:flex-col {
    flex-direction: column;
  }
  .sm\:flex-nowrap {
    flex-wrap: nowrap;
  }
  .sm\:items-start {
    align-items: flex-start;
  }
  .sm\:items-center {
    align-items: center;
  }
  .sm\:gap-2 {
    gap: 0.5rem;
  }
  .sm\:gap-4 {
    gap: 1rem;
  }
  .sm\:gap-6 {
    gap: 1.5rem;
  }
  .sm\:rounded-xl {
    border-radius: 0.75rem;
  }
  .sm\:bg-white {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  }
  .sm\:p-0 {
    padding: 0px;
  }
  .sm\:p-12 {
    padding: 3rem;
  }
  .sm\:p-6 {
    padding: 1.5rem;
  }
  .sm\:p-8 {
    padding: 2rem;
  }
  .sm\:px-24 {
    padding-left: 6rem;
    padding-right: 6rem;
  }
  .sm\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .sm\:pb-0 {
    padding-bottom: 0px;
  }
  .sm\:text-left {
    text-align: left;
  }
  .sm\:text-\[12px\] {
    font-size: 12px;
  }
  .sm\:text-sm {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
  .sm\:text-white {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
  }
  .group\/info:hover .sm\:group-hover\/info\:bg-gray-50 {
    --tw-bg-opacity: 1;
    background-color: rgb(245 247 250 / var(--tw-bg-opacity));
  }
  .group\/step:hover .sm\:group-hover\/step\:bg-gray-200 {
    --tw-bg-opacity: 1;
    background-color: rgb(203 210 217 / var(--tw-bg-opacity));
  }
  .group\/info:hover .sm\:group-hover\/info\:bg-gray-200 {
    --tw-bg-opacity: 1;
    background-color: rgb(203 210 217 / var(--tw-bg-opacity));
  }
  .group\/info:hover .sm\:group-hover\/info\:text-gray-300 {
    --tw-text-opacity: 1;
    color: rgb(154 165 177 / var(--tw-text-opacity));
  }
  .group\/step:hover .sm\:group-hover\/step\:text-gray-900 {
    --tw-text-opacity: 1;
    color: rgb(31 41 51 / var(--tw-text-opacity));
  }
  .group\/info:hover .sm\:group-hover\/info\:text-gray-900 {
    --tw-text-opacity: 1;
    color: rgb(31 41 51 / var(--tw-text-opacity));
  }
}

@media (min-width: 768px) {
  .md\:h-\[calc\(100vh-64px\)\] {
    height: calc(100vh - 64px);
  }
  .md\:h-full {
    height: 100%;
  }
  .md\:auto-rows-\[minmax\(0\2c 156px\)\] {
    grid-auto-rows: minmax(0,156px);
  }
  .md\:auto-rows-\[minmax\(0\2c 226px\)\] {
    grid-auto-rows: minmax(0,226px);
  }
  .md\:grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  .md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .md\:flex-row {
    flex-direction: row;
  }
  .md\:gap-0 {
    gap: 0px;
  }
  .md\:overflow-y-auto {
    overflow-y: auto;
  }
}

@media (min-width: 972px) {
  .header\:static {
    position: static;
  }
  .header\:inset-auto {
    inset: auto;
  }
  .header\:ml-6 {
    margin-left: 1.5rem;
  }
  .header\:block {
    display: block;
  }
  .header\:hidden {
    display: none;
  }
  .header\:items-stretch {
    align-items: stretch;
  }
  .header\:justify-start {
    justify-content: flex-start;
  }
  .header\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .header\:pr-0 {
    padding-right: 0px;
  }
}

@media (min-width: 1024px) {
  .lg\:max-w-\[86\%\] {
    max-width: 86%;
  }
  .lg\:max-w-xl {
    max-width: 36rem;
  }
  .lg\:auto-rows-\[minmax\(0\2c 196px\)\] {
    grid-auto-rows: minmax(0,196px);
  }
  .lg\:auto-rows-\[minmax\(0\2c 300px\)\] {
    grid-auto-rows: minmax(0,300px);
  }
  .lg\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  .lg\:flex-row {
    flex-direction: row;
  }
  .lg\:flex-nowrap {
    flex-wrap: nowrap;
  }
  .lg\:justify-start {
    justify-content: flex-start;
  }
  .lg\:justify-around {
    justify-content: space-around;
  }
  .lg\:p-8 {
    padding: 2rem;
  }
  .lg\:px-24 {
    padding-left: 6rem;
    padding-right: 6rem;
  }
  .lg\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (min-width: 1280px) {
  .xl\:auto-rows-\[minmax\(0\2c 226px\)\] {
    grid-auto-rows: minmax(0,226px);
  }
  .xl\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

@media (min-width: 1536px) {
  .\32xl\:auto-rows-\[minmax\(0\2c 180px\)\] {
    grid-auto-rows: minmax(0,180px);
  }
  .\32xl\:auto-rows-\[minmax\(0\2c 248px\)\] {
    grid-auto-rows: minmax(0,248px);
  }
  .\32xl\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}

.\[\&\>\*\:first-child\]\:rounded-t-md>*:first-child {
  border-top-left-radius: 0.375rem;
  border-top-right-radius: 0.375rem;
}

.\[\&\>\*\:last-child\]\:rounded-b-md>*:last-child {
  border-bottom-right-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
}
