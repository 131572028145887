@import 'src/styles/mixins';

.resources {
  & {
    margin-top: 50px;
  }

  .wrapper {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-bottom: 16px;
  }

  .card {
    width: 100%;
    padding: 16px;
    background: #f5f7fa;
    border-radius: 12px;
    display: flex;
    gap: 24px;
    flex: 1 1 auto;
    flex-wrap: wrap;
  }

  .imageWrapper {
    display: flex;
    justify-content: center;
    max-width: 150px;
  }

  .image {
    align-self: center;
    border: 1px solid #000000;
    max-width: 150px;
    object-fit: contain;
    filter: drop-shadow(4px 4px 0 #000000);
  }

  .cardInfo {
    flex: 1 1 auto;
  }

  .text {
    @include font-style-body-1;
    word-wrap: break-word;
    word-break: break-all;
  }

  .cardActions {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .link {
    @include font-style-body-2;

    display: inline-flex;
    align-items: center;
    gap: 6px;
    padding: 10px 16px 10px 12px;
    background: #ffffff;
    border: 1px solid #000000;
    border-radius: 6px;
    width: 100%;
  }

  .miro {
    padding: 8px;
  }
}

@media (min-width: 640px) {
  .resources {
    .cardActions {
      flex-direction: row;
    }
  }

  .link {
    max-width: fit-content;
  }
}

@media (min-width: 1280px) {
  .resources {
    .wrapper {
      flex-direction: row;
    }

    .cardActions {
      flex-direction: row;
    }
  }
}

@media (min-width: 1536px) {
  .resources {
    .cardActions {
      flex-direction: row;
    }
  }
}
