@import 'src/styles/mixins';

.header {
  display: flex;
  flex-direction: column;
  gap: 8px;
  & {
    padding: 0 32px 16px 0;
    border-bottom: 2px solid #000000;
  }

  .title {
    @include font-style-h4;
  }

  .text {
    @include font-style-body-3;
    margin-left: 9px;
  }
}

@media (min-width: 1024px) {
  .header {
    align-items: center;
    flex-direction: row;
    gap: 32px;
  }
}
