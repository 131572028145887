@media (min-width: 768px) {
  .noScrollbar {
    scrollbar-width: none;
    -ms-overflow-style: none;
  }

  .noScrollbar::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
}
